/* #app {
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-self: center;
} */

.main-app {
  width: 98%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  max-width: 550px;
  max-height: 800px;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

.main-header {
  height: 5%;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-bottom: 10px;
}

.btn-gamerules {
  align-items: flex-start;
  flex: 1;
}

.gamename {
  height: 40px;
  margin-left: 13%;
  margin-right: 26%;
  margin-top: 10px;
  font-weight: bold;
  font-family: "FreeMono", monospace;
  font-size: 23px;
  max-width: 100px;
}

.btn-winning {
  align-items: flex-end;
  flex: 1;
}

/* .hide {
  display: none;
}

.correctnumber:hover + .hide {
  display: block;
  color: red;
} */

.game {
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  height: 100vh;
  width: 100vw;
  max-width: 550px;
  max-height: 750px;
}

/* Tables */
.table-holder {
  border: 1px solid;
  overflow-y: scroll;
  height: 55%;
  max-height: 360px;
  width: 90%;
  display: flex;
  padding: 3px;
  margin: 0 auto;
  margin-bottom: -80px;
}

table,
th,
tr,
td {
  border: 2px solid;
}

.table {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  line-height: 50%;
}

th {
  font-size: 15px;
}

th:nth-child(1) {
  background: #d3d6da;
  width: 50%;
  letter-spacing: 2px;
}

th:nth-child(2) {
  width: 25%;
  background: #c9b458;
}

th:nth-child(3) {
  width: 25%;
  background: #6aaa64;
}

td {
  font-size: 20px;
}

td:nth-child(1) {
  width: 50%;
  letter-spacing: 5px;
}

td:nth-child(2) {
  background: #c9b458;
  width: 25%;
}

td:nth-child(3) {
  background: #6aaa64;
  width: 25%;
}

/* Input Screen */
.input-row {
  text-align: center;
  display: flex;
  height: 100px;
  width: 100%;
  margin-bottom: -100px;
}

.input-inner {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.input-box {
  width: 25%;
  height: 70%;
  max-width: 50px;
  background: #ffffff;
  color: black;
  font-size: 20px;
  font-weight: bold;
  border: 2px black;
  border-style: solid;
  text-align: center;
  margin: 5px;
}

/* Keypad */
.number-keypad {
  margin: 0 auto;
  display: flex;
  flex-flow: column wrap;
  width: 90%;
  height: 20vh;
}

.first-row {
  display: flex;
  flex-flow: row wrap;
  margin: auto;
  width: 100%;
}

.second-row {
  display: flex;
  flex-flow: row wrap;
  margin: auto;
  width: 90%;
}

.button {
  height: 8vh;
  width: 12%;
  font-size: 20px;
  line-height: 10px;
  text-align: center;
  margin: auto;
  background-color: #e7e7e7;
  color: black;
  border-color: #222;
  padding: 0px;
}

.button-zero {
  height: 8vh;
  width: 12%;
  font-size: 20px;
  line-height: 10px;
  margin: auto;
  background-color: #e7e7e7;
  color: black;
  border-color: #222;
  text-align: center;
  padding: 0px;
}

.button-back {
  height: 8vh;
  width: 25%;
  font-size: 20px;
  line-height: 10px;
  margin: auto;
  background-color: #e7e7e7;
  color: black;
  border-color: #222;
  text-align: center;
  padding: 0px;
}

.button-enter {
  height: 8vh;
  width: 25%;
  font-size: 20px;
  line-height: 10px;
  margin: auto;
  background-color: #e7e7e7;
  color: black;
  border-color: #222;
  text-align: center;
  padding: 0px;
}

button[disabled="disabled"],
button:disabled {
  background-color: #cccccc;
  color: #666666;
}

/* Popups */
.popup {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #222;
  overflow-y: scroll;
}

.popup-inner-rules {
  position: relative;
  padding: 28px;
  width: 80%;
  height: 100%;
  max-height: 500px;
  max-width: 640px;
  background-color: #f0f1f3;
  border: 2px solid;
  text-align: center;
  margin-top: 5%;
  line-height: 20px;
}

.popup-inner-stats {
  position: relative;
  padding: 28px;
  width: 80%;
  max-height: 550px;
  max-width: 640px;
  margin-top: 3%;
  background-color: #f0f1f3;
  border: 2px solid;
  text-align: center;
  line-height: 20px;
}

.stat-holder-text {
  display: flex;
  width: 100%;
}

.stat-tries {
  text-align: right;
  width: 10%;
  margin-right: 10px;
  margin-bottom: 8px;
}

.stat-bars {
  width: 90%;
}

.stat-individual {
  color: white;
  height: 8%;
  border: 1px solid;
  text-align: right;
  margin-bottom: 9px;
  background-color: #666666;
  padding: 2px;
}

.x-btn {
  position: absolute;
  color: black;
  right: 3%;
  top: 3%;
}

.present-digit {
  font-size: 20px;
  font-weight: bold;
  color: #c9b458;
}

.correct-digit {
  font-size: 20px;
  font-weight: bold;
  color: #6aaa64;
}

.bold-this {
  font-weight: bold;
}

.hr {
  height: 5px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/* Animations */

.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-apear-active {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 600ms linear;
}
